<template>
  <div
    :class="'agroup-page ' + LANGCODE"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\';'"
  >
    <div class="header">
      <div class="base-info">
        <van-image
          round
          width="20vw"
          height="20vw"
          :src="agentInfo.AgentFace ? agentInfo.AgentFace : defaultFace"
          fit="cover"
        />
        <div class="info">
          <span class="name">{{ agentInfo.AgentName }}</span>
          <span class="item">ID: {{ agentInfo.AgentCode }}</span>
          <span class="item" @click="onCopy(agentInfo.InviteCode)"
            >{{ PAGELANG.邀请码 }}: {{ agentInfo.InviteCode }}</span
          >
        </div>
      </div>
    </div>

    <div class="children-content">
      <van-button
        icon="aog iconfont icon-qrcode"
        block
        color="var(--van-card-price-color)"
        @click="showQRCode = true"
      >
        {{ PAGELANG.邀请注册 }}
      </van-button>

      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        :pulling-text="SYSLANG.下拉即可刷新"
        :loosing-text="SYSLANG.释放即可刷新"
        :loading-text="SYSLANG.loading"
      >
        <van-list
          v-model:loading="isloading"
          :finished="finished"
          :finished-text="SYSLANG.finished"
          :loading-text="SYSLANG.loading"
          @load="getUserList"
          :offset="50"
        >
          <ul class="user-list"></ul>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>

  <van-popup v-model:show="showQRCode" :style="{ padding: '30px' }" round>
    <div class="qrcode">
      <van-image
        width="50vw"
        height="50vw"
        :src="agentInfo.DistributorsQRCode"
        lazy-load
        radius="4px"
      />
      {{ PAGELANG.邀请链接 }}
      <input
        class="link"
        type="text"
        readonly
        :value="agentInfo.DistributorsLink"
        @click="onCopy"
      />
    </div>
  </van-popup>
</template>

<script>
import { closeToast, showLoadingToast, showToast } from "vant";
import { getCookie } from "../util";
export default {
  data() {
    return {
      agentInfo: {},
      defaultFace: require("@/assets/images/default_face.png"),
      list: [],
      refreshing: false,
      finished: false,
      isloading: false,
      curpage: 0,
      pagecount: 0,
      showQRCode: false,
    };
  },
  beforeCreate() {
    if (!getCookie("agentloginid")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentloginid")) {
      return;
    }

    this.PAGELANG = this.LANG.pages.agentgroup;
    this.SYSLANG = this.LANG.system;
    this.LANGCODE = this.langcode;
    this.UPLOADBASE = this.uploadbase;

    showLoadingToast({
      message: this.SYSLANG.loading,
      duration: 0,
      forbidClick: true,
    });

    this.axios.get(this.actions.invite).then(({ data }) => {
      closeToast();
      let { code, msg, agentInfo } = data;
      if (code == "SUCCESS") {
        agentInfo.DistributorsLink =
          this.UPLOADBASE +
          "#/agentgroup/invite?u=" +
          agentInfo.fkUserID +
          "&a=" +
          agentInfo.ID +
          "&t=1";
        this.agentInfo = agentInfo;
      } else {
        showToast({
          message: code == "NOTLOGIN" ? this.SYSLANG[msg] : this.GOODSLANG[msg],
          forbidClick: true,
          onClose: () => {
            if (code == "NOTLOGIN") {
              this.$router.replace({
                path: "/login",
              });
            }
          },
        });
      }
    });
  },
  methods: {
    // 下拉刷新数据
    onRefresh() {
      this.creditlogs = [];
      this.isloading = false;
      this.finished = false;
      this.curpage = 0;

      this.getUserList();
    },
    getUserList() {
      if (this.refreshing) {
        this.refreshing = false;
      }
      this.finished = true;

      this.curpage++;
      this.axios
        .post(this.actions.invite_user, {
          type: 1,
          curpage: this.curpage,
        })
        .then(({ data }) => {
          closeToast();
          let { code, msg, invite } = data;
          let { curpage, pagecount, list } = invite;
          console.log("list", list);

          if (code == "SUCCESS") {
            this.curpage = curpage * 1;
            this.pagecount = pagecount * 1;
            this.list = this.list.concat(list);
          } else {
            showToast({
              message:
                code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg],
              forbidClick: true,
              onClose: () => {
                if (code == "NOTLOGIN") {
                  this.$router.replace({
                    path: "/login",
                  });
                }
              },
            });
          }

          this.isloading = false;
          this.finished = false;

          if (this.curpage >= this.pagecount) {
            this.finished = true;
          }
        });
    },
    onCopy() {
      let oInput = document.createElement("input");
      oInput.value = this.agentInfo.DistributorsLink;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value);
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.remove();
      showToast(this.SYSLANG.copysuccess);
    },
  },
};
</script>

<style src="../assets/css/agentgroup.css" scoped>
</style>
<style scoped>
.children-content {
  padding: calc(120px + var(--aog-credit-menu-bottom-margin)) 16px 50px 16px;
}

.qrcode {
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 36px;
  font-size: 12px;
}

.qrcode .link {
  width: 100%;
  height: 36px;
  font-size: 14px;
  color: #000;
  border: 1px solid #ddd;
  box-sizing: border-box;
  border-radius: 4px;
}
</style>